:root {
  --primary: #0b1e40;
  --secondary: #f8f9fa;
  --accent: #f8f9fa;
  --text: #0b1e40;
  --text-light: #f8f9fa;
  --text-gray: #333;
  --text-dark: #0b1e40;
  --background: var(--primary);
  --background-light: #f8f9fa;
  --background-dark: #031535;
}

body {
  color: var(--text);
  background-color: var(--background);
}

.app-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
}

.app-icon {
  background: url(/public/turnfm-logo.png) no-repeat;
  background-size: contain;
  width: 140px;
  height: 140px;
  margin-bottom: 24px;
}

.app-icon-animated {
  animation: App-logo-spin infinite 1.81s linear;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.stations-container {
  width: 100%;
  max-width: 768px;
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: var(--background-light);
  padding: 12px;
}

.brand-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.action-button {
  background-color: var(--primary);
  color: var(--text-light);
  border: none;
  padding: 0.5rem 1rem;
  margin: 0.5rem;
  cursor: pointer;
  border-radius: 4px;
}
.action-button-small {
  padding: 6px 12px !important;
  font-size: 14px !important;
  background-color: var(--text-gray);
}
.action-button:hover {
  transition: background-color 0.3s;
  background-color: var(--background-dark);
}

.station-name {
  font-size: 22px;
  font-weight: bold;
  margin-bottom: 6px;
}

.station-description {
  font-size: 16px;
  color: var(--text-gray);
}

.station-container {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 24px;
  border: 1px solid var(--primary);
  margin-bottom: -1px;
  flex-direction: column;
}

.action-button-stop {
  background-color: crimson;
  color: var(--text-light);
}

.action-button-stop:hover {
  background-color: darkred;
}

.rhap_container {
  box-shadow: none;
  background-color: var(--background-light);
  width: 100%;
}

.rhap_progress-section {
  display: none;
}

.rhap_repeat-button {
  opacity: 0;
}

.component-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px;
}

.currently-playing {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 16px;
  background-color: #ddd;
  width: calc(100% - 32px);
}

.record-item {
  width: 320px;
  height: 320px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  padding: 4px;
}

.record-title {
  background-color: rgba(255, 255, 255, 0.5);
  padding: 6px;
  border-radius: 4px;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.25);
  opacity: 0;
}

.record-artists {
  background-color: rgba(255, 255, 255, 0.5);
  padding: 6px;
  border-radius: 4px;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.25);
  opacity: 0;
}
