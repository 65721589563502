/* src/components/Home.css */

.stations-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 16px;
}

.brand-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 24px;
}

.app-icon {
  width: 140px;
  height: 140px;
  background: url(/public/turnfm-logo.png) no-repeat;
  background-size: contain;
}

.app-icon-animated {
  animation: App-logo-spin infinite 1.81s linear;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.stations-list {
  width: 100%;
  max-width: 600px;
}

.station-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  background-color: #fff;
  border-radius: 8px;
  margin-bottom: 16px;
}

.station-label {
  flex-grow: 1;
  text-decoration: none;
  color: inherit;
}

.station-name {
  font-size: 18px;
  font-weight: bold;
}

.station-description {
  font-size: 14px;
  color: #666;
}

.action-button {
  padding: 10px;
  border-radius: 5px;
  background-color: #1e90ff;
  color: #fff;
  cursor: pointer;
  text-align: center;
}

.action-button-stop {
  background-color: #ff4040;
}
